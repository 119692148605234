@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 2px var(--cst-cards-item-primary-outline-default);
  border-radius: var(--cornerradius12);

  @include singleFixedSize(140px);

  @include min-1440-break {
    @include singleFixedSize(190px);
  }

  &.tertiary {
    aspect-ratio: 1 /1;

    @include fixedSize(100%, 100%);

    .item-shop {
      z-index: 1;
      pointer-events: none;

      @include singleFixedSize(96px);

      @include min-1440-break {
        @include singleFixedSize(120px);
      }
    }
  }

  &.quaternary {
    @include singleFixedSize(120px);
    box-shadow: none;
    align-self: center;
  }

  .item-shop {
    z-index: 1;
    pointer-events: none;

    @include singleFixedSize(68px);

    @include min-1440-break {
      @include singleFixedSize(96px);
    }
  }
}

.tags-info-container {
  position: absolute;
  z-index: 3;
  gap: 4px;
  display: flex;
  flex-direction: column;

  bottom: 8px;
  left: 8px;

  @include min-1440-break {
    bottom: 12px;
    left: 12px;
  }

  .tag-info {
    padding: 2px 6px;
    height: 21px;
    border-radius: var(--cornerradius8);
    @include min-1440-break {
      padding: 3px 8px;
      height: 24px;
      border-radius: var(--cornerradius8);
    }

    p {
      @include typography-xs;
      @include weight-medium;

      @include min-1440-break {
        @include typography-s;
      }
    }
  }
}

.fav-btn-container {
  position: absolute;
  transition: all 0.2s ease-in-out;

  gap: 4px;
  display: flex;
  flex-direction: column;
  top: 8px;
  right: 8px;

  @include min-1440-break {
    top: 12px;
    right: 12px;
  }
}
