@use '../../../app/queries' as *;
@use '../../../shared/styles/fixed' as *;

.tab-item {
  max-height: 30px;
  padding: 8px;
  border-radius: var(--cornerradius10);
  gap: 6px;

  > span {
    @include singleFixedSize(14px);
  }

  @include min-428-break {
    padding: 6px;
    gap: 8px;

    > span {
      @include singleFixedSize(18px);
    }
  }

  @include min-744-break {
    padding: 8px;
    gap: 6px;

    > span {
      @include singleFixedSize(14px);
    }
  }

  @include min-1024-break {
    max-height: 22px;
    padding: 4px;
    gap: 6px;

    > span {
      @include singleFixedSize(14px);
    }
  }

  @include min-1440-break {
    max-height: 30px;
    padding: 6px;
    gap: 8px;

    > span {
      @include singleFixedSize(18px);
    }
  }
}
