@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.info-block {
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10;
  height: 60px;
  width: 100%;
  @include min-1440-break {
    height: 75px;
  }

  .info-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .info-title {
      color: var(--cst-cards-item-primary-title-default);
      width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      transition: all 0.2s ease;
      &:hover {
        color: var(--cst-cards-item-primary-descriptiontext-hover);
      }

      @include typography-s;
      @include weight-medium;

      @include min-1440-break {
        @include typography-base;
        @include weight-medium;
      }
    }

    .info-age {
      color: var(--cst-cards-item-primary-type-text-default);
      width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      transition: all 0.2s ease;

      @include typography-s;
      @include weight-medium;

      @include min-1440-break {
        @include typography-base;
        @include weight-medium;
      }
    }

    .info-price-tags {
      display: flex;
      width: 100%;
      gap: 6px;
      align-items: center;
      .info-price-current {
        color: var(--cst-cards-item-primary-newpricetext-default);

        @include typography-m;
        @include weight-bold;

        @include min-1440-break {
          @include typography-xl;
          @include weight-bold;
        }
      }

      .info-price-old {
        color: var(--cst-cards-item-primary-oldpricetext-default);
        @include typography-s;
        @include weight-semi-bold;
      }
    }
  }
}
